const ROUTES = {
    HOME: '/',
    LOGIN: '/login',
    REGISTER: '/register',
    FORGOT_PASSWORD: '/forgot-password',
    RESET_PASSWORD: '/reset-password',
    CREATE: '/create',
    EDIT: '/edit',
    SHOW: '/show',
    PIB: '/pib',
    AIRPORT: '/airport',
    AREA: '/area',
    ROUTE: '/route',
    NARROW: '/narrow',
    POINT: '/point',
    POLYGON: '/polygon',
    HANDBOOK: '/briefing',
    FPL: '/flightplan',
    OVERVIEW: '/overview',
    TEMPLATE: '/template',
    AIP: '/aip',
    ADMIN: '/admin',
    USERS: '/users',
    TRANSLATIONS: '/translations',
    FEEDBACK: '/feedback',
    PAGES: '/pages',
    NOTICES: '/notices',
    GROUPS: '/groups',
    DEFAULT_SETTINGS: '/default-settings',
    MENU_DISPLAY_SETTINGS: '/menu-display-settings',
    LOGS: '/logs',
    STATISTICS: '/statistics',
    PREFLIGHTS: '/preflights',
    FLIGHTS: '/flights',
    GROUP_REQUESTS: '/group-requests',
    PERSONAL: '/personal',
    AERODROMES: '/aerodromes',
    FIRS: '/firs',
    ROUTES: '/routes',
    SETTINGS: '/settings',
    CMS: '/cms',
    PERSONAL_SETTINGS: '/personal-settings',
    INFORMATION: '/information',
    FLIGHTS_STATISTICS: '/flights-statistics',
    USER_STATISTICS: '/user-statistics',
    PIB_SETTINGS: '/briefing-settings',
    FPL_SETTINGS: '/fpl-settings',
    PREVIEW: '/preview',
    MAP_PREVIEW: '/map-preview',
    MESSAGES: '/messages',
    PRECONFIGURED_PIB: '/pre-configured-pib',
}

export default ROUTES
