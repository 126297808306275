const USER_TOKEN = 'fpl_token'
const USER_REFRESH_TOKEN = 'fpl_refresh_token'
const USER_ID = 'fpl_user_id'
const USER_LANGUAGE = 'fpl_user_language'

export const getUserToken = () => {
    return localStorage.getItem(USER_TOKEN)
}

export const setUserToken = (token) => {
    localStorage.setItem(USER_TOKEN, token)
}

export const deleteUserToken = () => {
    localStorage.removeItem(USER_TOKEN)
}

export const getUserRefreshToken = () => {
    return localStorage.getItem(USER_REFRESH_TOKEN)
}

export const setUserRefreshToken = (token) => {
    localStorage.setItem(USER_REFRESH_TOKEN, token)
}

export const deleteUserRefreshToken = () => {
    localStorage.removeItem(USER_REFRESH_TOKEN)
}

export const getUserId = () => {
    return Number(localStorage.getItem(USER_ID))
}

export const setUserId = (id) => {
    localStorage.setItem(USER_ID, id)
}

export const deleteUserId = () => {
    localStorage.removeItem(USER_ID)
}

export const getUserLanguage = () => {
    return localStorage.getItem(USER_LANGUAGE)
}

export const setUserLanguage = (code) => {
    localStorage.setItem(USER_LANGUAGE, code)
}

export const deletUserLanguage = () => {
    localStorage.removeItem(USER_LANGUAGE)
}

export const clearStorage = () => {
    deleteUserToken()
    deleteUserRefreshToken()
    deleteUserId()
}
